import styled from 'styled-components';

export const Title = styled.dt`
  display: block;
  line-height: 1.3;
  margin: 0 5% 1vh 5%;
  color: var(--dark-color);
  font-weight:600;

`;
