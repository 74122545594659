import styled from 'styled-components';
import {Media} from "../media/media"

export const Heading = styled.h1 `
  display: block;
  margin: 1em auto 0 auto;
  font-size: 2em;
  line-height: 1.2;
  text-align: center;
  font-weight: 400;
  text-transform:uppercase;
  letter-spacing: 2px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--dark-color);
  border-bottom: solid 2px var(--main-color);
  width: fit-content;
  :before{
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    content:'';
    background:var(--main-color);
    width:1.2em;
    height:0.05em;
  }


  ${Media.sm`
    font-size: 1.5em;
  `}
`;
