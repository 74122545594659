import classNames from 'classnames'
import {
  changeLocale,
  injectIntl,
  IntlContextConsumer,
} from 'gatsby-plugin-intl'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'


class LanguageSwitcher extends Component {
  getImageByCode(code) {
    return code === 'en' ? enFlag : plFlag
  }

  renderLanguageChoice(code, label, currentLocale) {
    return (
      <button
        key={code}
        className={classNames('lang-item', 'link-button', {
          active: currentLocale === code,
        })}
        onClick={() => changeLocale(code)}
      >
        {label}
      </button>
    )
  }

  render() {
    const languageNames = [
      { code: 'pl', label: 'PL' },
      { code: 'en', label: 'EN' },
      { code: 'de', label: 'DE' },
    ]

    return (
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <div className="dropdown ml-lg-2">
            <Helmet>
              <html lang={currentLocale} />
            </Helmet>
            <div className="lang-menu" aria-labelledby="langDropdown" >
              {languageNames.map(l =>
                this.renderLanguageChoice(l.code, l.label, currentLocale)
              )}
            </div>
          </div>
        )}
      </IntlContextConsumer>
    )
  }
}

export default injectIntl(LanguageSwitcher)