import styled from 'styled-components';

export const SrvText = styled.section `
  display: block;
  line-height: 1.5;
  padding: 5vh 5% 5vh 5%;
  margin-bottom:5vh;
  letter-spacing: -0.003em;
  color: var(--dark-color-light);
  background: var(--bg-color);
`;
