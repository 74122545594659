import styled from 'styled-components';
import {Media} from "../media/media"

export const Icon = styled.div`
  display: block;
  padding:auto;
  line-height: 1.5;
  margin: 0 5% 5vh 5%;
  letter-spacing: -0.003em;
  color: var(--dark-color-light);

  img{
    min-width:60px;
    margin: auto;
    align-self:center;
    vertical-align:middle;
    
    ${Media.sm`
      width: 40px;
    `}
  }

`;
