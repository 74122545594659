import styled from 'styled-components';
import {Media} from "../media/media"

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 1em auto;
  padding: 0.3em 1.2em;
  width: fit-content;
  font-size: 1.5em;
  font-weight: normal;
  font-family:'DM Serif Display', serif;
  border-radius: 0;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  transition: box-shadow 342ms cubic-bezier(0.23, 1, 0.32, 1);

  :hover {
    background-color: var(--main-color);
    color:white;
  }

  :active {
    background-color: var(--main-color);
    color:white;
  }

   ${Media.sm`
    font-size: 1em;
  `}

`;
