import { createGlobalStyle } from 'styled-components';
import {Media} from "../media/media"

export const GlobalStyles = createGlobalStyle`
  :root {
    white-space: pre-line;
    --sides-padding-desktop: 3%;
    --sides-padding-mobile: 5%;
    --main-color: #003F22;
    --accent-color: hsl(339, 100%, 55%);
    --bg-color: #FAF3EC;
    --font-gold: #FCD6A7;
    --light-color: hsl(0, 0%, 99%);
    --light-color-translucent: hsla(0, 0%, 99%, 0.92);
    --dark-color: hsl(0, 0%, 15%);
    --dark-color-light: hsla(0, 0%, 15%, 0.9);
    --dark-color-lighter: hsla(00, 0%, 15%, 0.7);
    --serif: 'DM Serif Display', serif;
    --sansserif: 'Lato', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

/* LOGO */

 .navbar-brand{
   padding:0.6em;
 }

/* Navigation menu */

  nav{
    position:fixed;
    width:100%;
    maxWidth:1900px;
    padding:0.5em 0;
    margin:0 auto;
    z-index:9999;
    display: flex;
    flexDirection: row
  }

  .menu{
      padding: 0px 1em;
      max-width: 1400px;
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      

    .navbar-nav{
      padding: 0 1em;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      ${Media.sm`
        flex-direction: column ;
        padding: 0.2em 1.5em;
      `}
    }
  }

  .nav-item{
      color:var(--bg-color);
      font-weight:500;
      border:solid 2px transparent;
      padding: 0.2em 0.6em;
    &:hover{
      border:solid 2px var(--font-gold);
      color: var(--font-gold);
    }
  }

  .whitenav{
      .nav-item{
        color:var(--main-color);
        font-weight:500;
        border:solid 2px transparent;
        padding: 0.2em 0.6em;
      &:hover{
        border:solid 2px var(--main-color);
        color: var(--main-color);
      }
    }
  }


/* Language menu */

  nav{
    .lang-menu{
      ${Media.sm`
        flex-direction: column;
      `}
    }
  }

  .lang-menu{
    display: flex;
    flex-direction:row;
    wrap:nowrap;      
  }

  .lang-item.active{
    color:var(--font-gold);
    text-decoration:underline;
    font-weight:bold;
  }
  .lang-item{
    color:var(--bg-color);
    padding: 0 10px;
    background:none;
    border-radius:0;
    border-left:0;
    border-top:0;
    border-bottom:0;
    border-right: solid 2px var(--font-gold);
  }
  .lang-item:last-child{
    background:none;
    border-radius:0;
    border-left:0;
    border-top:0;
    border-bottom:0;
    border-right: 0;
    }

  
  .whitenav{
    .lang-item.active{
      color:var(--main-color);
    }
    .lang-item{
      color:var(--main-color);
      border-right: solid 2px var(--main-color);
    }
  }  

  body {
    padding:0;
    min-height:90vh;
    background-color: var(--light-color);
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight:300;
    color: var(--dark-color);

    ${Media.sm`
      font-size: 16px;
    `}
  }

  /* CSS Reset */

  /* Box sizing rules */
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ol[class],
  ul[class] {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    font-weight: 400;
    font-family:'DM Serif Display', sans-serif;
    color:var(--dark-color);
  }

  /* Remove default margin */
  blockquote,
  body,
  dd,
  dl,
  figcaption,
  figure,
  h1,
  h2,
  h3,
  h4,
  li,
  ol[class],
  p,
  ul[class] {
    margin: 0;
  }


  /* Set core body defaults */
  body {
    line-height: 1.5;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    display: block;
    max-width: 100%;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  button,
  input,
  select,
  textarea {
    font: inherit;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .fof{
    padding:20vh;
  }

  .fof-text{
    text-align:center;
  }

  /*busiines and private clients*/
  .srvlist{
    max-width:900px;
    margin: auto !important;
    padding-top: 2em;
    padding-bottom: 1em;
  }

  .srvdesc{
    max-width: 1230px;
    margin:auto;
    font-size: 1em;
  }

  .header_private{
    padding-top:0;
    background:var(--bg-color);
    
    .gatsby-image-wrapper{
      height:400px;
          picture{
              img{
                object-position:top !important;
              }
          }        
    }

  }

  .header_private div{
    width:100%;
  }
  .header_private section{
    background:var(--bg-color);
  }

  .header_business{
    padding-top:0;
    background:var(--bg-color);
    
    .gatsby-image-wrapper{
      height:400px;
    }
  }
  .header_business div{
    width:100%;
  }
  .header_business section{
    background:var(--bg-color);
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      scroll-behavior: auto !important;
      transition-duration: 0.01ms !important;
    }
  }


/* ABOUT */

.aboutHero {
  padding:7em; 
  backgroundColor:var(--bg-color);
  
  h1{
    font-size:2em;
  }

  ${Media.sm`
    padding: 13em 0 0 0; 
`}


  .aboutHeroRow{
    margin-top: 70px;
    width:100%;

    ${Media.sm`
        margin-top: 32px;
    `}
  }



  .aboutHeroInner {
  width:150%;
  padding:0;
  margin:0;
  margin-right:2em;
  text-align:left;

    ${Media.sm`
      width:100%;
    `}

    h1{
      ${Media.sm`
          font-size:1.5em;
          line-height:1em;
      `}
    }
  }
}

/* SERVICES */

.srvlist{
  padding: 1em;
}
`;
