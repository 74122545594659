import styled from 'styled-components';
import {Media} from "../media/media"

export const Hero = styled.section `
  position:relative;
  padding: 0;
  display: flex;
  flex-direction:column;
  line-height: 1.2em;
  text-align: right;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  max-width: 1920px;
  margin: auto;

  .gatsby-image-wrapper{
    min-height:40vh;
    width:100%;
    flex-direction:row;
  }

  h1{
    color: black;
    font-weight: 400;
  }

  ${Media.sm`
    padding-top:110px;
  `}
`;