import styled from 'styled-components';
import {Media} from "../media/media"


export const AboutMe = styled.section `
  padding:50px 1px;
  width:100%;
  display: flex;
  flex-direction:column;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  background: #fafafa;
        ${Media.sm`
        padding:0;
        margin:auto;
    `}

  .AboutMeTxt {
    width:170%;
    margin:auto;
    padding:2em;
    margin-right:4em;

      ${Media.sm`
        width:100%;
        padding:0;
        margin:auto;
    `}

    .prefirst{
      color: var(--main-color);
      text-align:right;
      font-size:1.5em;
      line-height:2em;
      padding:.3em 0;
      font-weight:300;
      margin-bottom:0;
      
      ${Media.sm`
        padding:0;
        margin:auto;
        text-align:left;
    `}
    }

    h1{
      font-size:2.5em;
      line-height:1.5em;
      text-align:right;

      ${Media.sm`
        font-size:2em;
        padding:0;
        margin:auto;
        text-align:left;
    `}
    }

    p{
      text-align:left;
      padding:1em 0;
      color: black;
      font-weight:200;
      margin-bottom:1em;
    }

  }

  .gatsby-image-wrapper{
    
    padding:75px;
    
    ${Media.sm`
      display: none;
    `}
  }

`;