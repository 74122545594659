import styled from 'styled-components';
import {Media} from "../media/media"

export const SectionRow = styled.div `
  padding:0px var(--sides-padding-desktop);
  max-width: 1440px;
  margin:auto;
  display: flex;
  flex-direction:row;

  ${Media.sm`
      flex-direction:column;
      width:100%;
  `}
`;
