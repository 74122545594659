import styled from 'styled-components';
import {Media} from "../media/media"

export const Service = styled.section `
  margin: 1.5em ;
  display: flex;
  flex-direction:column;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  
  ${Media.sm`
    margin: 0 ;

    h1{
      font-size:1.5em;
      padding: 1em 0;
    }

    p{
      padding: 0 0 2em; 0;
    }

    a{
      margin:0 auto 2em auto
    }
  `}
`;