import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import LanguageSwitcher from './LanguageSwitcher'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Row } from '../Row';



const NavLink = ({id,name, additionalClasses }) => (
  <a className={`nav-item nav-link ${additionalClasses}`} style={{cursor:'pointer'}} onClick={() => scrollTo('#'+id)}>
    {name}
  </a>
)

NavLink.defaultProps = {
  additionalClasses: '',
}

class Header extends React.Component {
  render() {
    let intl = this.props.intl

    return (
      <nav
        className="navbar navbar-expand-lg fixed-top border-bottom navbar-light"
        style={{
          background: 'var(--main-color)',
          boxShadow: '0px 3px 10px var(--main-color)',
          margin:'auto',
        }}
      >
          <Link className="navbar-brand" to="/" >
            <h1 style={{fontSize:'2em', color:'var(--font-gold)'}}>URS</h1>
          </Link>
        <div className="menu">
            <div className="navbar-nav">
              <Link className="nav-item" to="/about">{intl.formatMessage({ id: 'button.about' })}</Link> 
              <Link className="nav-item" to="/bussines">{intl.formatMessage({ id: 'bussines.title' })}</Link>
              <Link className="nav-item" to="/private-clients">{intl.formatMessage({ id: 'private.title' })}</Link> 
              <NavLink
                id="contact"
                name={intl.formatMessage({ id: 'nav_link3' })}
              />
            </div>
          <LanguageSwitcher/>
        </div>
      </nav>
    )
  }
}

export default injectIntl(Header)