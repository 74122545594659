import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';

import { Footer, GlobalStyles } from '.';
import Header from './Header/'

const changeColor=()=>{
  let color = document.documentElement.style.getPropertyValue('--main-color');
  if (color == "#f54336") {
    document.documentElement.style.setProperty('--main-color', '#003f22');
  }
  else if(color == "#3f51b5") {
    document.documentElement.style.setProperty('--main-color', '#f54336');
  }
  else{
    document.documentElement.style.setProperty('--main-color', '#3f51b5');
  }
}

const Change = styled.p`
  position:absolute;
  cursor:pointer;
  top:100px;
  right:50px;
  padding:0.3em;
  font-weight:400;
  background:var(--main-color);
  color:white;
`

const Content = styled.div`
  padding: 0;
  margin: 0 auto;
  max-width: 1920px;
`;

export const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      {/* <Change onClick={changeColor} >Zmień kolor</Change> */}
      <Header />
      <Content>{children}</Content>
      <Footer />
      {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
          This site uses cookies ...
      </CookieConsent> */}
    </>
  );
};
