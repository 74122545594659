import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';

import { Footer, GlobalStyles } from '.';
import HeaderWhite from './HeaderWhite/'

const Content = styled.div`
  padding: 0;
  margin: 0 auto;
  max-width: 1920px;

`;

export const Layout_srv = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <HeaderWhite />
      <Content>{children}</Content>
      <Footer />
      {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
          This site uses cookies ...
      </CookieConsent> */}
    </>
  );
};
