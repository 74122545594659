import styled from 'styled-components';

export const Section = styled.section `
  margin:auto;
  display: flex;
  flex-direction:column;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  background: #fafafa;
`;