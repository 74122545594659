import styled from 'styled-components';

export const Desc = styled.dl`
  display: block;
  line-height: 1.5;
  margin: 0 5% 5vh 5%;
  letter-spacing: -0.003em;
  color: var(--dark-color-light);

`;
