import styled from 'styled-components';

export const Services = styled.section `
  padding:50px 1em;
  width:100%;
  display: flex;
  flex-direction: column;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  background: var(--bg-color);

`;