import styled from 'styled-components';
import {Media} from "../media/media"

export const SecTitle = styled.h1 `
  position:relative;
  font-weight: 400;
  font-size: 40px;
  line-height:1em;
  color: black;
  display: inline-block;
  width: fit-content;
  padding: 0.1em;
  margin: auto;
  text-transform: Capitalize;
  :before{
    position: absolute;
    top:-5px;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    content:'';
    background:var(--main-color);
    width:1.2em;
    height:0.05em;
  }
`;
