import React from 'react';
import styled from 'styled-components';
import { injectIntl, Link } from 'gatsby-plugin-intl'
import LanguageSwitcher from './Header/LanguageSwitcher';
import {Row} from './'
import scrollTo from 'gatsby-plugin-smoothscroll';
import {Media} from "../media/media"


const NavLink = ({id,name, additionalClasses }) => (
  <a className={`nav-item nav-link ${additionalClasses}`} style={{cursor:'pointer'}} onClick={() => scrollTo('#'+id)}>
    {name}
  </a>
)

NavLink.defaultProps = {
  additionalClasses: '',
}

const Container = styled.footer`
  padding: 5vh var(--sides-padding-desktop) 3vh var(--sides-padding-desktop);
  margin-top: 0;
  text-align: left;
  font-size: 17px;
  background:var(--main-color);
  color:white;

  .rfirst{
    justify-content: space-between;

    ${Media.sm`
      flex-direction:column;
    `}

    .navbar-foot{
      padding:0 2em 0 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .nav-item:first-child {
        padding:0.2em 0.6em 0.2em 0;
      }

      ${Media.sm`
        flex-direction:column;

      .nav-item:first-child {
        padding:0.4em 0.6em 0.4em 0.6em;
      }
      `}
    }
  }

  .lang-menu{
    button{
    color: white;
    border-color:white;
    }
  }


  .nav-item{
      color:white;
      font-weight:500;
      border:solid 1px transparent;
      padding: 0.2em 0.6em;
    &:hover{
      border: solid 1px white !important;
      color:white !important;
    }
  }


`;



export const Footer = injectIntl(({ intl }) => {
  return (
    <Container>
      <Row className="rfirst">
        <div className="navbar-foot">
        <NavLink  
          id="office"
          name={intl.formatMessage({ id: 'nav_link1' })}
        />
        <NavLink
          id="services"
          name={intl.formatMessage({ id: 'nav_link2' })}
        />
        <Link className="nav-item" to="/bussines">{intl.formatMessage({ id: 'bussines.title' })}</Link>
        <Link className="nav-item" to="/private-clients">{intl.formatMessage({ id: 'private.title' })}</Link>
        <NavLink
          id="contact"
          name={intl.formatMessage({ id: 'nav_link3' })}
        />
      </div>
      <LanguageSwitcher></LanguageSwitcher>
      </Row>
      <Row className="rsecond">
        <p>
          © {new Date().getFullYear()}, {intl.formatMessage({ id: 'footer_title' })}
        </p>
      </Row>
    </Container>
  );
});
